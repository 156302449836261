import React, { useState } from 'react';
import { Paper, Button, TextField } from '@material-ui/core';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from '../types/SiteMetadata';
import styled from '../styled';
import Layout from '../layouts/EmptyLayout';
import { SEO } from '../components/SEO';

type TagMap = { [name: string]: string };

let gtag: any;

if (typeof window !== 'undefined') {
  gtag = (window as any).gtag;
} else {
  gtag = () => {};
}

type Data = {
  allTag: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
  site: {
    siteMetadata: SiteMetadata;
  };
};

const Title = styled('div')`
  color: ${(p) => p.theme.palette.primary.contrastText};
  font-size: 1.8rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Content = styled('p')`
  text-align: left;
  font-size: 1.5rem;
  margin: 2rem auto;

  a {
    border-bottom: 2px solid white;
  }

  a:focus {
    outline: blue;
  }
`;

const SuccessMessage = styled('div')`
  border: 1px solid #bae637;
  border-radius: 8px;
  background-color: #eaff8f;
  padding: 12px 24px;
  color: #254000;
  font-size: 18px;
`;

const ErrorMessage = styled('div')`
  border: 1px solid #ffc53d;
  border-radius: 8px;
  background-color: #ffe58f;
  color: #613400;
  font-size: 18px;
  padding: 12px 24px;
`;

const InnerFormWrapper = styled('form')`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-row-gap: 24px;
  grid-column-gap: 12px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const YOUR_FORM_ID = '1645703';
const YOUR_SUBFORM_ID = '7611';
const YOUR_FORM_URL = `https://app.convertkit.com/forms/${YOUR_FORM_ID}/subscriptions`;

const InnerForm = () => {
  const urlParam = (() => {
    if (typeof window === 'undefined') {
      return '';
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || '';
  })();

  const [status, setStatus] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(urlParam);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);

    try {
      data.append('fields[source]', document.referrer);
      const response = await fetch(YOUR_FORM_URL, {
        method: 'post',
        body: data,
        headers: {
          accept: 'application/json'
        }
      });

      const json = await response.json();

      if (json.status === 'success') {
        setStatus('SUCCESS');
        try {
          gtag('event', 'Pause Subscription', {
            event_category: 'Newsletter',
            event_label: 'Email Course'
          });
        } catch (err) {}
        return;
      }

      setStatus('ERROR');
    } catch (err) {
      setStatus('ERROR');
    }
  };

  return (
    <div>
      <InnerFormWrapper
        action={YOUR_FORM_URL}
        method="post"
        onSubmit={handleSubmit}
      >
        {status !== 'SUCCESS' && (
          <>
            <TextField
              label="Your email"
              name="email_address"
              placeholder="Your email address"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{
                style: { backgroundColor: 'white', borderRadius: '8px' }
              }}
              required
              fullWidth
              type="email"
            />
            <input
              id={`tag-${YOUR_SUBFORM_ID}-1840879`}
              type="checkbox"
              style={{ display: 'none' }}
              checked
              name="tags[]"
              value="1840879"
            />
            <Button
              size="large"
              style={{
                padding: '12px 24px',
                fontSize: '16px'
              }}
              color="primary"
              variant="contained"
              type="submit"
            >
              Pause subscription
            </Button>
          </>
        )}
      </InnerFormWrapper>
      {status === 'SUCCESS' && (
        <SuccessMessage>You're all set! Enjoy your break :)</SuccessMessage>
      )}
      {status === 'ERROR' && (
        <ErrorMessage>
          There was an error pausing your subscription! Is your privacy browser
          extension possibly blocking the request to my newsletter provider? Try
          again or email me at hi@bloggingfordevs.com to pause.
        </ErrorMessage>
      )}
    </div>
  );
};

export default function () {
  const data: Data = useStaticQuery(graphql`
    query {
      allTag {
        nodes {
          name
          id
        }
      }
      site {
        siteMetadata {
          title
          siteUrl
          description
          headline
          footerText
          footerUrl
        }
      }
    }
  `);

  const tags: string[] = [];
  const siteMetadata = data.site.siteMetadata;
  const allTags = data.allTag.nodes;
  const tagMap = allTags.reduce<TagMap>((result, tag) => {
    result[tag.name] = tag.id;
    return result;
  }, {});

  console.log(tags);
  console.log(tagMap);

  return (
    <div style={{ padding: '12px' }}>
      <Layout styles={{ maxWidth: 650, margin: '0 auto' }}>
        <SEO
          title={siteMetadata.title}
          description={siteMetadata.description}
          siteUrl={siteMetadata.siteUrl}
        />
        <Title>
          <h1>Taking a break?</h1>
        </Title>
        <Content>
          Just confirm your email address below and emails from Blogging for
          Devs will be paused for 30 days.
        </Content>
        <InnerForm />
      </Layout>
    </div>
  );
}
